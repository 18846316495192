import React, { useContext, useEffect, useState } from "react";
import Title from "../mainContentComponent/Title";
import PackageDisplay from "../mainContentComponent/package/PackageDisplay";
import { packages } from "../../types/packagesTypes";
import { contentTextDefault, yourteVente } from "../helper/defaultDataContent";
import DescriptionVenteYourte from "../mainContentComponent/DescriptionVenteYourte";
import { defaultContent } from "../../types/defaultContent";
import { getDefaultContents } from "../../services/defaultContentService";
import Comments from "../mainContentComponent/Comments";
import BookingPolicy from "../mainContentComponent/BookingPolicy";
import ContactFooter from "../mainContentComponent/ContactFooter";
import Footer from "../mainContentComponent/Footer";
import { getPackagesYourteSell } from "../../services/packageService";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../contexts/AuthProvider";
import { UserContextType } from "../../types/userType";

type IProps = {
  hideShowBookingBox: () => void;
};

const Accueil: React.FC<IProps> = ({ hideShowBookingBox }) => {
  const { language } = useContext(AuthContext) as UserContextType;

  const [packagesYourteVenteState, setPackagesYourteVenteState] = useState<packages[]>(yourteVente);
  const [fetchedTextContent, setFetchedTextContent] =
    useState<defaultContent[]>(contentTextDefault);
  const title = language === "Eng" ? "Yurt for Sale" : "Vente De Yourte";

  useEffect(() => {
    setPackagesYourteVenteState(yourteVente);
    setFetchedTextContent(contentTextDefault);
    getPackages();
  }, []);

  useEffect(() => {
    getDefaultContentsTexts();
  }, [language]);

  const getPackages = async () => {
    try {
      const fetchedPackagesYourte: packages[] = await getPackagesYourteSell();
      setPackagesYourteVenteState(fetchedPackagesYourte);
    } catch (err) {
      throw new Error(err as string);
    }
  };

  const getDefaultContentsTexts = async () => {
    try {
      const fetchedDefaultContents: defaultContent[] = await getDefaultContents();

      const tmpDefaultContentByLanguage: any = [];

      if (language === "") {
        fetchedDefaultContents.forEach((content) => {
          if (!content.language.includes("en")) {
            tmpDefaultContentByLanguage.push(content);
          }
        });
      } else {
        fetchedDefaultContents.forEach((content) => {
          if (content.language.includes("en")) {
            tmpDefaultContentByLanguage.push(content);
          }
        });
      }

      setFetchedTextContent(tmpDefaultContentByLanguage as defaultContent[]);
    } catch (err) {
      throw new Error(err as string);
    }
  };

  useEffect(() => {
    getDefaultContentsTexts();
  }, []);

  useEffect(() => {}, [fetchedTextContent]);

  // END-------------------Retrieving mainContent-----------------------------------***
  const jsonLdVenteYourte = {
    "@context": "https://schema.org",
    "@type": "Produit",
    name: "Vente de Yourte Mongole",
    description: "Vente de yourte au Québec",
    provider: {
      "@type": "LocalBusiness",
      name: "Aventure Quatre Saisons",
      url: "https://www.aventurequatresaisons.com"
    }
  };
  return (
    <div>
      <Helmet>
        <meta
          name="title"
          content={
            language === "Eng"
              ? "Sale of high quality authentic Mongolian yurts"
              : "Vente de yourte authentique mongole de haute qualité."
          }
        />
        <meta
          name="Description"
          content={
            language === "Eng"
              ? "Imported directly from Mongolia, our yurts are made almost entirely by hand. Painted wood, felt and high quality decorated canvas as well as braided horsehair ropes make our yurts a high-end product appreciated by many. They can be delivered with traditional furniture. Much of the material we import comes directly from rural areas of Mongolia to help stem the exodus, a scourge in this country of nomads."
              : "Importées directement de Mongolie, nos yourtes sont faites presque entièrement à la main. Bois peint, feutre et toile décorée de haute qualité ainsi que cordes tressées en crin de cheval font de nos yourtes un produit haut de gamme apprécié de plusieurs. Elles peuvent être livrées avec le mobilier traditionnel. Une grande partie du matériel que nous importons provient directement des zones rurales de Mongolie afin d'aider à freiner l'exode, un fléau dans ce pays de nomades."
          }
        />

        <title>
          {language === "Eng"
            ? "Sale of high quality authentic Mongolian yurts"
            : "Vente de yourte authentique mongole de haute qualité."}
        </title>
        <script type="application/ld+json">{JSON.stringify(jsonLdVenteYourte)}</script>
      </Helmet>
      <div>
        <div className="sm:m-8 duration-300 sm:w-auto">
          <div>
            <Title title={title}></Title>
          </div>
          <div
            id="displayPackageRef"
            className="flex h-[auto] sm:h-[700px] bg-white sm:w-[100%]  text-justify">
            <PackageDisplay
              packagesCanoe={packagesYourteVenteState}
              hideShowBookingBox={hideShowBookingBox}></PackageDisplay>
          </div>
        </div>
        <div className="sm:m-8 sm:w-auto duration-300 p-2 text-justify">
          <DescriptionVenteYourte defaultTexts={fetchedTextContent[3]}></DescriptionVenteYourte>
        </div>
        <div className="sm:m-8  sm:w-auto hover:scale-[102%] p-2 duration-300 text-justify">
          <Comments defaultTexts={fetchedTextContent[2]}></Comments>
        </div>
        <div className="sm:m-8 sm:w-auto  hover:scale-[102%] p-2 duration-300  text-justify">
          <BookingPolicy defaultTexts={fetchedTextContent[1]}></BookingPolicy>
        </div>
        <div className="sm:m-8 sm:w-auto hover:scale-[102%] p-2 duration-300  text-justify">
          <ContactFooter></ContactFooter>
        </div>
        <div>
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
};

export default Accueil;
