import React, { useContext, useEffect, useState } from "react";
import { packages } from "../../types/packagesTypes";
import { getPackagesJorring } from "../../services/packageService";
import MainContentPage from "../mainContentComponent/MainContentPage";
import { jorringPackages } from "../helper/defaultDataContent";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../contexts/AuthProvider";
import { UserContextType } from "../../types/userType";

type IProps = {
  hideShowBookingBox: () => void;
  languageUrl?: string;
};

const RaquetteTractee: React.FC<IProps> = ({ languageUrl = "", hideShowBookingBox }) => {
  const { setNewLanguage, language } = useContext(AuthContext) as UserContextType;
  const [packagesCanoeState, setPackagesCanoeState] = useState<packages[]>(jorringPackages);
  const title = language === "Eng" ? "SnowShoe Joring" : "Raquette Tractée";

  // ***-------------------Retrieving Activity-----------------------------------***

  useEffect(() => {
    if (languageUrl === "Eng") {
      setNewLanguage("Eng");
    } else {
      setNewLanguage("");
    }
    getPackages();
  }, []);

  const getPackages = async () => {
    try {
      const fetchedPackagesCanoe: packages[] = await getPackagesJorring();
      setPackagesCanoeState(fetchedPackagesCanoe);
    } catch (err) {
      throw new Error(err as string);
    }
  };

  const jsonLdRaqTrac = {
    "@context": "https://schema.org",
    "@type": "Service",
    name: "Raquette Tracté pas chiens de traineau",
    description: "randonnée tracté par chiens de traineau",
    provider: {
      "@type": "LocalBusiness",
      name: "Aventure Quatre Saisons",
      url: "https://www.aventurequatresaisons.com"
    }
  };
  // END-------------------Retrieving Activity-----------------------------------***
  return (
    <div>
      <Helmet>
        <meta
          name="title"
          content={
            language === "Eng"
              ? "Hike towed by sled dogs Mont-Tremblant"
              : "Randonnée Tractée par chiens de traîneau Mont-Tremblant"
          }
        />
        <meta
          name="Description"
          content={
            language === "Eng"
              ? "Trail riding is an unknown sport but quickly appreciated by dog ​​lovers. Easily adaptable according to the moods of the group, the relationship between the human and the dog is deepened throughout the walk by a great interaction."
              : "La randonnée tractée est un sport méconnu mais rapidement apprécié des amoureux des chiens. Facilement adaptable selon les humeurs du groupe, la relation entre l'humain et le chien est approfondie tout au long de la marche par une grande interraction."
          }
        />
        <title>
          {language === "Eng"
            ? "Hike towed by sled dogs Mont-Tremblant"
            : "Randonnée Tractée par chiens de traîneau Mont-Tremblant"}
        </title>
        <script type="application/ld+json">{JSON.stringify(jsonLdRaqTrac)}</script>
      </Helmet>
      <div className="z-20 w-full">
        <MainContentPage
          title={title}
          packagesCanoe={packagesCanoeState}
          hideShowBookingBox={hideShowBookingBox}></MainContentPage>
      </div>
    </div>
  );
};

export default RaquetteTractee;
