import React, { useContext } from "react";
import bgImage from "../../../src/images/bgtest.png";
import OpenBookingMenuButton from "./OpenBookingMenuButton";
import { v4 as uuidv4 } from "uuid";
import { defaultContent } from "../../types/defaultContent";
import EditableTextPackage from "../editableLiveForm/EditableTextPackage";
import { AuthContext } from "../../contexts/AuthProvider";
import { UserContextType } from "../../types/userType";

type IProps = {
  defaultTexts: defaultContent;
  hideShowBookingBox: () => void;
};

const Description: React.FC<IProps> = ({ defaultTexts, hideShowBookingBox }) => {
  const { currentUser, isLoggedContextState } = useContext(AuthContext) as UserContextType;

  return (
    <div
      className="bg-gradient-to-r to-aqs-green-400 from-aqs-blue-500 text-base rounded-b-2xl rounded-3xl border-[1px] border-white overflow-hidden"
      id="description">
      <div className=" bg-cover text-white" style={{ backgroundImage: `url(${bgImage})` }}>
        {isLoggedContextState && currentUser.admin === 1 ? (
          <div>
            <div className="flex flex-col items-center bg-aqs-blue-50/50">
              <div className="p-2 font-josefin text-2xl font-bold ">
                <EditableTextPackage
                  key={uuidv4()}
                  selectedPackage={defaultTexts}
                  attToModif={"title"}
                  routeToPatch={"textContent"}
                />
              </div>
              <div>
                <OpenBookingMenuButton key={uuidv4()} hideShowBookingBox={hideShowBookingBox} />
              </div>

              <div className=" text-2xl font-bold  ">
                <hr className="mx-[10%]" />
                <EditableTextPackage
                  key={uuidv4()}
                  selectedPackage={defaultTexts}
                  attToModif={"subtitle"}
                  routeToPatch={"textContent"}
                />
                <hr className="mx-[10%]" />
              </div>
              <div>
                <EditableTextPackage
                  key={uuidv4()}
                  selectedPackage={defaultTexts}
                  attToModif={"description"}
                  routeToPatch={"textContent"}
                />
              </div>
              <hr />
              <div className=" text-2xl font-bold ">
                <hr className="mx-[10%]" />
                <EditableTextPackage
                  key={uuidv4()}
                  selectedPackage={defaultTexts}
                  attToModif={"secondSubtitle"}
                  routeToPatch={"textContent"}
                />
                <hr className="mx-[10%]" />
              </div>
              <div className="p-4">
                <EditableTextPackage
                  key={uuidv4()}
                  selectedPackage={defaultTexts}
                  attToModif={"secondDescription"}
                  routeToPatch={"textContent"}
                />
              </div>
              <hr />
              <div>
                <OpenBookingMenuButton key={uuidv4()} hideShowBookingBox={hideShowBookingBox} />
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{ whiteSpace: "pre-line" }}
            className="flex flex-col items-center bg-aqs-blue-50/50">
            <div className="p-2 font-josefin text-2xl font-bold ">
              <h3>{defaultTexts?.title}</h3>
            </div>
            <div>
              <OpenBookingMenuButton key={uuidv4()} hideShowBookingBox={hideShowBookingBox} />
            </div>
            <div className=" text-2xl font-bold  ">
              <hr className="mx-[10%]" />
              <h3 className="p-2">{defaultTexts?.subtitle}</h3>
              <hr className="mx-[10%]" />
            </div>
            <div>
              <h3 className="p-4">{defaultTexts?.description}</h3>
            </div>
            <hr />
            <div className=" text-2xl font-bold ">
              <hr className="mx-[10%]" />
              <h3 className="p-2">{defaultTexts?.secondSubtitle}</h3>
              <hr className="mx-[10%]" />
            </div>
            <div className="p-4">
              <h3>{defaultTexts?.secondDescription}</h3>
            </div>
            <hr />
            <div>
              <OpenBookingMenuButton key={uuidv4()} hideShowBookingBox={hideShowBookingBox} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Description;
