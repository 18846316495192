import React, { useEffect, useRef, useState } from "react";
import { newBooking } from "../../services/bookingService";
import { packages } from "../../types/packagesTypes";
import { reservation } from "../../types/reservationTypes";
import BookingPolicyCheckout from "./BookingPolicyCheckout";
import { TalkToUser } from "../helper/TalkToUser";
import { hideCardNumber } from "../helper/CreditCardNumber";

type IProps = {
  reservationState: reservation;
  checkoutBoxToggle: () => void;
  selectedPackage: packages;
  hideBookingBox: () => void;
  resetBookingState: () => void;
};

const Accueil: React.FC<IProps> = ({
  reservationState,
  checkoutBoxToggle,
  selectedPackage,
  hideBookingBox,
  resetBookingState
}) => {
  const [checked, setChecked] = useState(false);
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const [yourteActivity, setYourteActivity] = useState(false);

  useEffect(() => {
    if (reservationState.yourte != "aucune") {
      setYourteActivity(true);
    } else {
      setYourteActivity(false);
    }
  }, [reservationState]);

  const submitRes = async () => {
    try {
      const newBookingData = await newBooking(reservationState);
      if (newBookingData.activite) {
        setBookingSuccess(true);
      } else {
        setBookingSuccess(false);
      }
      checkoutBoxToggle();
    } catch (err) {
      throw new Error(err as string);
    }
  };

  const [checkoutBoxVisible, setPolicyBoxVisible] = useState(true);
  const policy = useRef<HTMLDivElement>(null);
  const policyBoxToggle = async (): Promise<void> => {
    if (checkoutBoxVisible) {
      setPolicyBoxVisible(false);
    } else {
      setPolicyBoxVisible(true);
    }
  };
  useEffect(() => {
    if (checkoutBoxVisible) {
      policy.current!.className = "relative invisible opacity-0 h-0 mr-36";
    } else {
      policy.current!.className =
        "absolute flex flex-col bg-aqs-blue-500 text-white w-auto sm:mr-36 mr-0 mx-4 border-2 border-black rounded-xl justify-between duration-1000";
    }
  }, [checkoutBoxVisible]);

  useEffect(() => {
    if (bookingSuccess) {
      resetBookingState();
      hideBookingBox();
      setBookingSuccess(false);
    }
  }, [bookingSuccess]);

  const uncheckPolicyAccept = async () => {
    setChecked(false);
  };

  const bookingSend = async () => {
    if (checked) {
      await submitRes();
      await uncheckPolicyAccept();
    } else {
      TalkToUser(`Vous devez accepter la politique de réservation.`);
    }
  };

  return (
    <div className="bg-aqs-green-300/90 sm:mx-32 mx-0 border-2 rounded-md text-xl">
      <div className="flex flex-row justify-center text-3xl p-4 underline">
        <p>Veuillez confirmer la transaction</p>
      </div>
      <div
        className="absolute flex flex-col bg-aqs-blue-500 text-white w-auto mr-36 mx-4 border-2 border-black rounded-xl justify-between"
        ref={policy}>
        <BookingPolicyCheckout policyBoxToggle={policyBoxToggle}></BookingPolicyCheckout>
      </div>

      <div className="flex flex-col-reverse sm:flex-row sm:justify-around m-4">
        <div className=" sm:border-l-8 p-3 sm:w-[50%] w-auto rounded-lg">
          <div className="flex flex-row p-3">
            <p className="font-bold">Forfait choisi:&nbsp;</p>
            <p className="text-white">{reservationState.activite}</p>
          </div>
          <div className="flex flex-row p-3">
            <p className="font-bold">Date de l&apos;activité:&nbsp;</p>
            <p className="text-white">{reservationState.startDate}</p>
          </div>
          <div className="flex flex-row p-3">
            <p className="font-bold">Durée:&nbsp;</p>
            {selectedPackage.activityType === "yourte" ? (
              <p className="text-white">{reservationState.duree / 100} nuitée</p>
            ) : selectedPackage.activityType === "winter" ? (
              <p className="text-white">{reservationState.duree * 10} heure</p>
            ) : (
              <p className="text-white">{reservationState.duree} jour</p>
            )}
          </div>
          <div className="flex flex-row p-3">
            <p className="font-bold">Heure du départ:&nbsp;</p>
            <p className="text-white">{reservationState.departureTime} H</p>
          </div>
          <div className="flex flex-row p-3">
            <p className="font-bold">Prénom:&nbsp;</p>
            <p className="text-white">{reservationState.prename}</p>
          </div>
          <div className="flex flex-row p-3">
            <p className="font-bold">Nom:&nbsp;</p>
            <p className="text-white">{reservationState.name}</p>
          </div>
          <div className="flex flex-row p-3">
            <p className="font-bold">Courriel:&nbsp;</p>
            <p className="text-white">{reservationState.courriel}</p>
          </div>
          <div className="flex flex-row p-3">
            <p className="font-bold">Nombre d&apos;adultes:&nbsp;</p>
            <p className="text-white">{reservationState.nbPaxAd}</p>
          </div>
          <div className="flex flex-row p-3">
            <p className="font-bold">Nombre d&apos;enfants:&nbsp;</p>
            <p className="text-white">{reservationState.nbPaxEnf}</p>
          </div>
          <div className="flex flex-row p-3">
            <p className="font-bold">Téléphone:&nbsp;</p>
            <p className="text-white">{reservationState.tel}</p>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row w-full sm:w-3/4 items-center">
          <img
            className="w-full border-2 rounded-md"
            src={selectedPackage.picUrl}
            alt={selectedPackage.name}
          />
        </div>
      </div>
      <div className="flex flex-col items-center">
        {yourteActivity ? (
          <div className="flex flex-col items-center m-3">
            <div className="flex font-bold">
              <p>Yourte:</p>
            </div>
            <div className="flex flex-row">
              <div>{reservationState.yourte}</div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center m-3">
            <div className="flex font-bold">
              <p>Embarquations:</p>
            </div>
            <div className="flex flex-row">
              <div className="flex flex-row p-3">
                <p>Canot:&nbsp;</p>
                <p className="text-white">{reservationState.nbCanoe}</p>
              </div>
              <div className="flex flex-row p-3">
                <p>Kayak:&nbsp;</p>
                <p className="text-white">{reservationState.nbKayak}</p>
              </div>
              <div className="flex flex-row p-3">
                <p>SUP:&nbsp;</p>
                <p className="text-white">{reservationState.nbSUP}</p>
              </div>
            </div>
          </div>
        )}

        <div className="m-3">
          <div className="flex flex-row font-bold">
            <p>Informations bancaires:</p>
          </div>
          <div className="flex flex-row">
            <p>Carte:&nbsp;</p>
            {reservationState.enterprise ? (
              <p className="text-white">{hideCardNumber(reservationState.enterprise.toString())}</p>
            ) : (
              <p className="text-white"></p>
            )}
          </div>
          <div className="flex flex-row">
            <p>Expiration:&nbsp;</p>
            <p className="text-white">
              {reservationState.expiryMonth}/{reservationState.expiryYear}
            </p>
          </div>
        </div>
        <hr className="w-[50%]" />
        <div className="m-3">
          <div className="flex justify-center flex-col items-center">
            <div className="flex flex-row">
              <p className="font-bold">Prix forfait:&nbsp;</p>
              <p className="text-white">{reservationState.pricing + " $"}</p>
            </div>
            <div className="flex flex-row">
              <p>TPS:&nbsp;</p>
              <p className="text-white">
                {((reservationState.pricing / 100) * 5).toFixed(2) + " $"}
              </p>
            </div>
            <div className="flex flex-row">
              <p>TVQ:&nbsp;</p>
              <p className="text-white">
                {((reservationState.pricing / 100) * 9.975).toFixed(2) + " $"}
              </p>
            </div>
          </div>
          <div className="flex flex-row justify-center sm:text-6xl text-4xl border-4 rounded-xl p-4">
            <p>Total:&nbsp;</p>
            <p className="text-white">
              {(
                reservationState.pricing * 1 +
                (reservationState.pricing / 100) * 9.975 +
                (reservationState.pricing / 100) * 5
              ).toFixed(2)}
              &nbsp;$
            </p>
          </div>
          <div className="flex flex-col sm:text-sm text-lg border-2 border-orange  bg-orange/40 rounded-xl m-1 text-center">
            <p className="text text-xl underline">
              ** Paiement <span className="text-white">INTERAC</span> possible &nbsp;**
            </p>
            <p className="text text-lg">
              Effectuer un transfert à :{" "}
              <span className="text-white">info@aventurequatresaisons.com</span>
            </p>
            <p className="text text-lg">
              avec la référence :<span className="text-white"> {reservationState.name}</span>
            </p>
            <p className="text-white text-lg">
              au montant exact de :
              {(
                reservationState.pricing * 1 +
                (reservationState.pricing / 100) * 9.975 +
                (reservationState.pricing / 100) * 5
              ).toFixed(2)}{" "}
              &nbsp;$
            </p>
            <p className="text text-lg">
              Si votre transfert est reçu dans les <span className="text-white"> 15 minutes</span>{" "}
              <br></br>
              suivant votre réservation, votre carte de crédit ne sera pas débitée.
            </p>
          </div>
        </div>
        <hr className="w-[50%]" />
        <div className="m-3">
          <div className="flex flex-col text-center sm:sm:flex-row p-3">
            <p>Vous acceptez par la présente notre&nbsp;</p>
            <a href="#policy">
              <p className="hover:cursor-pointer text-white" onClick={policyBoxToggle}>
                politique de réservation.
              </p>
            </a>
          </div>
          <div className="flex justify-center p-3 underline font-bold items-center">
            <input
              type="checkbox"
              id="read"
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
              className="w-[25px] h-[25px] mx-2"
            />
            <label htmlFor="read">{"J'ai lu et j'accepte la politique de réservation."}</label>
          </div>
          <a href="#booking">
            <div
              className="flex flex-row hover:cursor-pointer justify-center border-2 border-red rounded-xl m-4 hover:bg-red/50 duration-1000"
              onClick={checkoutBoxToggle}>
              <p>Effectuer des modifications</p>
            </div>
          </a>
          {checked ? (
            <a href="#top">
              <div
                className="flex flex-row hover:cursor-pointer justify-center border-2 border-green rounded-xl m-4 hover:bg-green/50 duration-1000"
                onClick={bookingSend}>
                <p>Confirmer la transaction</p>
              </div>
            </a>
          ) : (
            <div
              className="flex flex-row hover:cursor-pointer justify-center border-2 border-green rounded-xl m-4 hover:bg-green/50 duration-1000"
              onClick={bookingSend}>
              <p>Confirmer la transaction</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Accueil;
