import React, { useContext, useEffect, useState } from "react";
import { packages } from "../../types/packagesTypes";
import { getPackagesCanoe } from "../../services/packageService";
import MainContentPage from "../mainContentComponent/MainContentPage";
import { canotCampingPackages } from "../helper/defaultDataContent";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../contexts/AuthProvider";
import { UserContextType } from "../../types/userType";

type IProps = {
  hideShowBookingBox: () => void;
  languageUrl?: string;
};

const CanotCamping: React.FC<IProps> = ({ languageUrl = "", hideShowBookingBox }) => {
  const { setNewLanguage, language } = useContext(AuthContext) as UserContextType;
  const [packagesCanoeState, setPackagesCanoeState] = useState<packages[]>(canotCampingPackages);
  const title = language === "Eng" ? "Canoe-Camping" : "Canot-Camping";

  // ***-------------------Retrieving Activity-----------------------------------***
  useEffect(() => {
    if (languageUrl === "Eng") {
      setNewLanguage("Eng");
    } else {
      setNewLanguage("");
    }
    getPackages();
  }, []);

  const getPackages = async () => {
    try {
      const fetchedPackagesCanoe: packages[] = await getPackagesCanoe();
      setPackagesCanoeState(fetchedPackagesCanoe);
    } catch (err) {
      throw new Error(err as string);
    }
  };

  const jsonLdCanotCamping = {
    "@context": "https://schema.org",
    "@type": "Service",
    name: "Canot-Camping",
    description: "Excursions auto guidé en Canot-Camping sur la belle Rivière-Rouge",
    provider: {
      "@type": "LocalBusiness",
      name: "Aventure Quatre Saisons",
      url: "https://www.aventurequatresaisons.com"
    }
  };

  // END-------------------Retrieving Activity-----------------------------------***
  return (
    <div>
      <Helmet>
        <meta
          name="title"
          content={
            language === "Eng" ? "Canoe-Camping in Rivière-Rouge" : "Canot-Camping à Rivière-Rouge"
          }
        />

        <meta
          name="description"
          content={
            language === "Eng"
              ? "Canoe-Camping on the Rivière-Rouge, Mont-Tremblant - Laurentides Canoe on the magnificent Rivière-Rouge."
              : "Canot-Camping sur la Rivière-Rouge, Mont-Tremblant, Laurentides"
          }
        />
        <title>
          {language === "Eng" ? "Canoe-Camping in Rivière-Rouge" : "Canot-Camping à Rivière-Rouge"}
        </title>
        <script type="application/ld+json">{JSON.stringify(jsonLdCanotCamping)}</script>
      </Helmet>
      <div className="z-20 w-full">
        <MainContentPage
          title={title}
          packagesCanoe={packagesCanoeState}
          hideShowBookingBox={hideShowBookingBox}></MainContentPage>
      </div>
    </div>
  );
};

export default CanotCamping;
