import React, { useContext, useEffect, useRef, useState } from "react";
import { ParseInventToSelectItem } from "../helper/ParseInventToSelectItem";
import imgBookingLeft from "../../images/imageBookingLeft.jpg";
import imgBookingRight from "../../images/imageBookingRight.jpg";
import { v4 as uuidv4 } from "uuid";
import { inventory } from "../../types/inventoryType";
import { postInventoryForDate } from "../../services/inventoryService";
import { packages, packagesContextType } from "../../types/packagesTypes";
import {
  emptyReservation,
  emptyInventory,
  disabledMondayThusday
} from "../helper/defaultDataContent";
import { PackagesContext } from "../../contexts/PackagesProvider";
import { reservation } from "../../types/reservationTypes";
import ValidEntry from "../authenticate/ValidEntry";
import { TalkToUser } from "./../helper/TalkToUser";
import Checkout from "./Checkout";
import {
  addDaysToDate,
  addDaysToDateSynchrone,
  dateToStringSynchrone,
  getDateAsDayValue,
  getNextDateStringFormat
} from "../helper/dateParsing";
import { AuthContext } from "../../contexts/AuthProvider";
import { UserContextType } from "../../types/userType";

import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker, { DayValue, Day } from "@hassanmojab/react-modern-calendar-datepicker";

type IProps = {
  hideBookingBox: () => void;
};

const hideInteracBox = (): void => {
  const interacBoxDiv: HTMLElement = document.getElementById("interacBoxDiv") as HTMLElement;

  interacBoxDiv.style.display = "none";
};

const Reserver: React.FC<IProps> = ({ hideBookingBox }) => {
  // ***-------------------Retrieving user logged ADMIN-----------------------------------***
  const { currentUser, isLoggedContextState, language } = useContext(
    AuthContext
  ) as UserContextType;

  // ***-------------------Retrieving inventory------------------------------------***

  // ***-------------------Day1 inventory-----------------------------------***
  const [inventoryDateSelect, setInventoryDateSelect] = useState<any[]>([emptyInventory]);

  // ***-------------------retrive 3 days of inventory-----------------------------------***
  const getInventoryByDateAndSetState = async (date: string) => {
    try {
      const inventoryDateTMP: any[] = [{}];
      const duration =
        selectedPackage.duration > 99 ? selectedPackage.duration / 100 : selectedPackage.duration; // To parse yourte duration 100,200,300
      for (let i = 0; i < duration; i++) {
        const fetchedInventoryDateSelect: inventory = await postInventoryForDate(
          await getNextDateStringFormat(date, i)
        );

        inventoryDateTMP[i] = fetchedInventoryDateSelect;
      }
      setInventoryDateSelect(inventoryDateTMP);
    } catch (err) {
      TalkToUser(
        `Le serveur est actuellement dans l'impossibilité de répondre.
        
         Veuillez nous contacter ou réessayer plus tard afin d'effectuer une réservation.
         
         Svp aviser le support technique. 
         
         Merci ! 
         
         514-554-5091 - info@aventurequatresaisons.com`
      );
      throw new Error(err as string);
    }
  };

  // END-------------------Retrieving inventory-----------------------------------***

  // ***-------------------ReservationState-----------------------------------***
  const [reservationState, setReservationState] = useState<reservation>(emptyReservation);

  const updateReservationState = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ): Promise<void> => {
    const reservationTmp = { ...reservationState, [event.target.id]: event.target.value };
    let validationBookingTmp = { ...bookingValidation, [event.target.id]: true };

    if (event.target.validity.patternMismatch) {
      validationBookingTmp = { ...bookingValidation, [event.target.id]: false };
    }

    if (event.target.validity.valueMissing) {
      validationBookingTmp = { ...bookingValidation, [event.target.id]: false };
    }
    setReservationState(reservationTmp);
    setBookingValidation(validationBookingTmp);
  };

  const updateReservationStateWithFloat = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ): Promise<void> => {
    const reservationTmp = {
      ...reservationState,
      [event.target.id]: parseFloat(event.target.value)
    };
    let validationBookingTmp = { ...bookingValidation, [event.target.id]: true };

    if (event.target.validity.patternMismatch) {
      validationBookingTmp = { ...bookingValidation, [event.target.id]: false };
    }

    if (event.target.validity.valueMissing) {
      validationBookingTmp = { ...bookingValidation, [event.target.id]: false };
    }
    //testing redeploy
    setReservationState(reservationTmp);
    setBookingValidation(validationBookingTmp);
  };

  const updateReservationStateDeparture = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ): Promise<void> => {
    const reservationTmp = { ...reservationState, [event.target.id]: event.target.value };
    let validationBookingTmp = { ...bookingValidation, [event.target.id]: true };

    if (event.target.validity.patternMismatch) {
      validationBookingTmp = { ...bookingValidation, [event.target.id]: false };
    }

    if (event.target.validity.valueMissing) {
      validationBookingTmp = { ...bookingValidation, [event.target.id]: false };
    }
    reservationTmp.nbPaxAd = 0;
    reservationTmp.nbPaxEnf = 0;
    setReservationState(reservationTmp);
    setBookingValidation(validationBookingTmp);
  };

  const updateReservationStateChangeActivity = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ): Promise<void> => {
    const reservationTmp = { ...reservationState, [event.target.id]: event.target.value };
    let validationBookingTmp = { ...bookingValidation, [event.target.id]: true };

    if (event.target.validity.patternMismatch) {
      validationBookingTmp = { ...bookingValidation, [event.target.id]: false };
    }

    if (event.target.validity.valueMissing) {
      validationBookingTmp = { ...bookingValidation, [event.target.id]: false };
    }
    reservationTmp.duree = 0;
    reservationTmp.departureTime = 0;
    setReservationState(reservationTmp);
    setBookingValidation(validationBookingTmp);
  };

  useEffect(() => {
    if (reservationState.startDate) {
      getInventoryByDateAndSetState(reservationState.startDate);
    }
  }, [reservationState.startDate]);
  // FIN-------------------ReservationState-----------------------------------***

  // ***-------------------Retrieving Packages-----------------------------------***
  const { packagesContextState } = useContext(PackagesContext) as packagesContextType;
  const [selectedPackage, setSelectedPackage] = useState<packages>(packagesContextState[0]);
  const [packageFilteredByLanguage, setPackageFilteredByLanguage] = useState<packages[]>([]);

  //Will flush all packages that are not in current language and flush yurt selling
  useEffect(() => {
    const tmpPackagesToSelect: any = [];

    if (language === "") {
      packagesContextState.forEach((pack) => {
        if (!pack.activityType.includes("Eng") && pack.activityType !== "yourteSell") {
          tmpPackagesToSelect.push(pack);
        }
      });
    } else {
      packagesContextState.forEach((pack) => {
        if (pack.activityType.includes("Eng") && pack.activityType !== "yourteSellEng") {
          tmpPackagesToSelect.push(pack);
        }
      });
    }

    setPackageFilteredByLanguage(tmpPackagesToSelect as packages[]);
  }, [packagesContextState, language]);

  useEffect(() => {
    packagesContextState.forEach((pack) => {
      if (pack.tag === reservationState.activite) {
        setSelectedPackage(pack);
      }
    });
  }, [reservationState.activite]);
  // END-------------------Retrieving Packages-----------------------------------***

  // ***-------------------sumbmitRes-----------------------------------***
  const [checkoutBoxVisible, setCheckoutBoxVisible] = useState(true);
  const checkout = useRef<HTMLDivElement>(null);

  const checkoutBoxToggle = async (): Promise<void> => {
    if (checkAllForm()) {
      try {
        if (checkoutBoxVisible) {
          setCheckoutBoxVisible(false);
        } else {
          setCheckoutBoxVisible(true);
        }
        // await newBooking(reservationState);
      } catch (err) {
        throw new Error(err as string);
      }
    } else {
      TalkToUser(
        `Vos champs sont mal remplis !

            Remplissez tous les champs en rouge svp.
            
            `
      );
    }
  };

  const resetBookingState = async (): Promise<void> => {
    setReservationState(emptyReservation);

    Object.keys(bookingValidation).forEach((v) => (bookingValidation[v] = false));
    bookingValidation.specialCode = true;
  };

  useEffect(() => {
    if (checkoutBoxVisible) {
      checkout.current!.className = "relative invisible opacity-0 h-0";
    } else {
      checkout.current!.className =
        "absolute bg-white/50 w-full h-full visible duration-1000 opacity-100 z-30";
    }
  }, [checkoutBoxVisible]);

  // ***-------------------Price Calcul-----------------------------------***

  const [messageConfirmation, setMessageConfirmation] = useState<string>("");
  useEffect(() => {
    CalculPricing();
  }, [
    selectedPackage,
    reservationState.nbCanoe,
    reservationState.nbKayak,
    reservationState.nbSUP,
    reservationState.nbPaxAd,
    reservationState.nbPaxEnf
  ]);

  const CalculPricing = async (): Promise<void> => {
    let finalPricing = 0;

    let nbEmbarcations =
      reservationState.nbCanoe * 2 + reservationState.nbKayak * 1 + reservationState.nbSUP * 1;
    const nbPax = reservationState.nbPaxAd * 1 + reservationState.nbPaxEnf * 1;
    const tropPax = nbPax - nbEmbarcations;
    const tropEmbarcation = nbEmbarcations - nbPax;
    const childsRate = 0.7;
    const shuttleOnlyRebate = 0.2;
    const noShuttleRental = 0.8;

    switch (selectedPackage.activityType) {
      case "summer":
        setMessageConfirmation("Le nombre de participants n'éguale pas le nombre de place");
        if (nbEmbarcations === nbPax) {
          finalPricing =
            reservationState.nbPaxAd * selectedPackage.pricing +
            reservationState.nbPaxEnf * selectedPackage.pricing * childsRate;
          setMessageConfirmation(
            "Votre nombre de participants balance avec les places dans les embarcations !"
          );
        }
        if (nbEmbarcations < nbPax) {
          finalPricing =
            reservationState.nbPaxAd * selectedPackage.pricing +
            reservationState.nbPaxEnf * selectedPackage.pricing * childsRate -
            (reservationState.nbPaxEnf > 0
              ? tropPax * childsRate * selectedPackage.pricing * shuttleOnlyRebate
              : tropPax * selectedPackage.pricing * shuttleOnlyRebate);
          setMessageConfirmation(
            `*** Il vous manque ${tropPax} places dans les embarcations, svp assurez-vous de votre choix. ***`
          );
        }
        if (nbEmbarcations > nbPax) {
          finalPricing =
            reservationState.nbPaxAd * selectedPackage.pricing +
            reservationState.nbPaxEnf * selectedPackage.pricing * childsRate +
            tropEmbarcation * selectedPackage.pricing * noShuttleRental;
          setMessageConfirmation(
            `*** Vous avez ${tropEmbarcation} places innutilisées dans les embarcations, svp assurez-vous de votre choix. Seule les personnes réservées pourrons prendre la navette.***`
          );
        }
        break;

      case "winter":
        finalPricing =
          reservationState.nbPaxAd * selectedPackage.pricing +
          reservationState.nbPaxEnf * selectedPackage.pricing * childsRate;
        setMessageConfirmation("Au plaisir de vous accueillir !");
        break;

      case "yourte":
        finalPricing = selectedPackage.pricing;
        setMessageConfirmation("Au plaisir de vous accueillir !");
        break;

      default:
        setMessageConfirmation("");
    }

    reservationState.pricing = finalPricing;
  };
  // FIN-------------------Price Calcul-----------------------------------***

  // ***-------------------Form SELECT contain STATES-----------------------------------***

  const [selectDurationState, setSelectDurationState] = useState<number>();

  const [selectdepartureTimeState, setSelectdepartureTimeState] = useState<string[]>([]);
  useEffect(() => {
    const departureTmpHTrim = selectedPackage.departureHour.replace(/[h ]/g, "");
    const departureTmp = departureTmpHTrim.split(",");
    setSelectdepartureTimeState(departureTmp);
    setSelectDurationState(selectedPackage.duration);
  }, [selectedPackage]);

  const [selectNbAdultsState, setSelectNbAdultsState] = useState<string[]>([]);
  useEffect(() => {
    let keySpotLeft = "spotLeft" + reservationState.departureTime;
    keySpotLeft = keySpotLeft.replace(/ /g, "");
    if (selectedPackage.activityType === "yourte" || selectedPackage.activityType === "yourteEng") {
      setSelectNbAdultsState(ParseInventToSelectItem("Adultes(s)", 4));
    } else {
      setSelectNbAdultsState(
        ParseInventToSelectItem("Adultes(s)", inventoryDateSelect[0][keySpotLeft])
      );
    }
  }, [selectdepartureTimeState, inventoryDateSelect, reservationState]);

  const [selectNbEnfantsState, setSelectNbEnfantsState] = useState<string[]>([]);
  useEffect(() => {
    let keySpotLeft = "spotLeft" + reservationState.departureTime;
    keySpotLeft = keySpotLeft.replace(/ /g, "");
    selectedPackage.activityType === "yourte" || selectedPackage.activityType === "yourteEng"
      ? setSelectNbEnfantsState(ParseInventToSelectItem("Enfants(s)", 4))
      : setSelectNbEnfantsState(
          ParseInventToSelectItem(
            "Enfants(s)",
            inventoryDateSelect[0][keySpotLeft] - reservationState.nbPaxAd
          )
        );
  }, [reservationState.nbPaxAd]);

  const [selectCanotState, setSelectCanotState] = useState<string[]>([]);
  useEffect(() => {
    // < 99 to avoid yourte case 100,200,300
    if (selectedPackage.duration < 99) {
      let minCanoeAvail = inventoryDateSelect[0]?.canoe;
      for (let i = 0; i < selectedPackage.duration; i++) {
        if (inventoryDateSelect[i]?.canoe < minCanoeAvail) {
          minCanoeAvail = inventoryDateSelect[i].canoe;
        }
      }
      setSelectCanotState(ParseInventToSelectItem("Canot(s)", minCanoeAvail));
    }
  }, [inventoryDateSelect]);

  const [selectKayakState, setSelectKayakState] = useState<string[]>([]);
  useEffect(() => {
    // < 99 to avoid yourte case 100,200,300
    if (selectedPackage.duration < 99) {
      let minKayakAvail = inventoryDateSelect[0]?.kayak;
      for (let i = 0; i < selectedPackage.duration; i++) {
        if (inventoryDateSelect[i]?.kayak < minKayakAvail) {
          minKayakAvail = inventoryDateSelect[i].kayak;
        }
      }
      setSelectKayakState(ParseInventToSelectItem("Kayak(s)", minKayakAvail));
    }
  }, [inventoryDateSelect]);

  const [selectSupState, setSelectSupState] = useState<string[]>([]);
  useEffect(() => {
    // < 99 to avoid yourte case 100,200,300
    if (selectedPackage.duration < 99) {
      let minSupAvail = inventoryDateSelect[0]?.sup;
      for (let i = 0; i < selectedPackage.duration; i++) {
        if (inventoryDateSelect[i]?.sup < minSupAvail) {
          minSupAvail = inventoryDateSelect[i].sup;
        }
      }
      setSelectSupState(ParseInventToSelectItem("Sup(s)", minSupAvail));
    }
  }, [inventoryDateSelect]);

  const [selectYourteState, setSelectYourteState] = useState<string[]>([]);
  useEffect(() => {
    let minperchee = inventoryDateSelect[0]?.yourtePerchee;
    let mintrad = inventoryDateSelect[0]?.yourteTrad;
    let minvoyageuse = inventoryDateSelect[0]?.yourteVoyageuse;

    for (let i = 0; i < selectedPackage.duration; i++) {
      if (inventoryDateSelect[i]?.yourtePerchee < minperchee) {
        minperchee = inventoryDateSelect[i].yourtePerchee;
      }
      if (inventoryDateSelect[i]?.yourteTrad < mintrad) {
        mintrad = inventoryDateSelect[i].yourteTrad;
      }
      if (inventoryDateSelect[i]?.yourteVoyageuse < minvoyageuse) {
        minvoyageuse = inventoryDateSelect[i].yourteVoyageuse;
      }
    }

    let selectYourteTMP = [];
    if (minvoyageuse > 0) selectYourteTMP.push("voyageuse");
    if (mintrad > 0) selectYourteTMP.push("tradditionnelle");
    if (minperchee > 0) selectYourteTMP.push("perchee");

    setSelectYourteState(selectYourteTMP);
  }, [inventoryDateSelect]);
  // END-------------------Form SELECT contain STATES-----------------------------------***

  // ***-------------------Validation-----------------------------------***

  const [bookingValidation, setBookingValidation] = useState<reservation | any>({
    activite: false,
    startDate: false,
    duree: false,
    departureTime: false,
    nbPaxAd: false,
    prename: false,
    name: false,
    nbCanoe: false,
    yourte: false,
    nbKayak: false,
    nbSUP: false,
    courriel: false,
    tel: false,
    modePaiement: false,
    enterprise01: false,
    enterprise: false,
    specialCode: true,
    expiryMonth: false,
    expiryYear: false
  });

  const checkAllForm = (): boolean => {
    const allRegExpOk = Object.keys(bookingValidation).every((k) => bookingValidation[k]); //Validate if all keys are true
    let formAllGood = true;
    if (
      reservationState.prename === undefined ||
      reservationState.name === undefined ||
      reservationState.courriel === undefined ||
      reservationState.tel === undefined ||
      reservationState.yourte === ""
    ) {
      formAllGood = false;
    }
    if (!allRegExpOk) {
      formAllGood = false;
    }

    return formAllGood;
  };

  const provideValidOrNotCss = (validOrNot: boolean): string => {
    return `sm:w-auto w-[300px] p-3 rounded border-[1px] ${
      validOrNot
        ? " border-green bg-green/10 text-white/80"
        : " border-red/70 bg-red/10 valid:border-[1px]  text-white/60"
    }`;
  };

  //Reset to 0 fir hided SELECT on change ACTIVITY summer/yourte/sled
  useEffect(() => {
    reservationState.nbCanoe = 0;
    reservationState.nbKayak = 0;
    reservationState.nbSUP = 0;
    reservationState.yourte = "aucune";
    reservationState.startDate = "";
    bookingValidation.nbCanoe = false;
    bookingValidation.nbKayak = false;
    bookingValidation.nbSUP = false;
    bookingValidation.yourte = false;
    bookingValidation.startDate = false;
    bookingValidation.duree = false;
    bookingValidation.departureTime = false;
  }, [selectedPackage]);

  const summerValidityBecauseNotVisible = (visibleOrNot: boolean) => {
    bookingValidation.nbCanoe = visibleOrNot;
    bookingValidation.nbSUP = visibleOrNot;
    bookingValidation.nbKayak = visibleOrNot;
    return "";
  };

  const yourteValidityBecauseNotVisible = (visibleOrNot: boolean) => {
    bookingValidation.yourte = visibleOrNot;
    return "";
  };
  // END-------------------Validation-----------------------------------***

  const [width, setWidth] = React.useState(window.innerWidth);

  const breakpoint = 1100;
  const AreWeMobile = (): Boolean => {
    if (width < breakpoint) {
      return true;
    } else {
      return false;
    }
  };
  const [areWeMobileState, setAreWeMobileState] = React.useState<Boolean>(AreWeMobile());

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    if (AreWeMobile() !== areWeMobileState) {
      setAreWeMobileState(AreWeMobile());
    }
  }, [width]);

  // ***-------------------Date Picker and validation-----------------------------------***

  const [todayState] = useState<Day>(getDateAsDayValue(new Date()));
  // eslint-disable-next-line no-unused-vars
  const [tomorrowState, setTomorrowState] = useState<Day>(
    getDateAsDayValue(addDaysToDateSynchrone(new Date(), 1))
  );
  const [SummerStartDate] = useState<Day>({
    day: todayState.month >= 6 && todayState.month <= 9 ? new Date().getDate() : 15,
    month: todayState.month >= 6 && todayState.month <= 9 ? new Date().getMonth() + 1 : 6,
    year: new Date().getFullYear()
  });
  const [maxSummerDateState] = useState<Day>({
    day: 2,
    month: 10,
    year: new Date().getFullYear()
  });
  const [winterStartDate] = useState<Day>({
    day: todayState.month >= 1 && todayState.month <= 4 ? new Date().getDate() : 10,
    month: todayState.month >= 1 && todayState.month <= 4 ? new Date().getMonth() + 1 : 12,
    year: new Date().getFullYear()
  });
  const [maxWinterDateState] = useState<Day>({
    day: 10,
    month: 4,
    year: todayState.month <= 4 ? new Date().getFullYear() : new Date().getFullYear() + 1
  });
  const [areWeWinterState] = useState<boolean>(false);

  useEffect(() => {
    getTomorrow();
  }, [todayState]);
  useEffect(() => {
    areWeInWinter();
  }, [winterStartDate]);

  useEffect(() => {}, [areWeWinterState]);

  const getTomorrow = async (): Promise<void> => {
    setTomorrowState(getDateAsDayValue(await addDaysToDate(new Date(), 1)));
  };

  const areWeInWinter = async (): Promise<void> => {
    // setAreWeWinterState((await stringToDate(todayState)) > (await stringToDate(winterStartDate)));
  };

  // ----------------------Date Picker and validation-----------------------------------***
  const [datePickerState, setdatePickerState] = useState<DayValue>({
    day: addDaysToDateSynchrone(new Date(), 1).getDate(),
    month: addDaysToDateSynchrone(new Date(), 1).getMonth() + 1,
    year: addDaysToDateSynchrone(new Date(), 1).getFullYear()
  });

  const handledatePickerStateChange = (newValue: any) => {
    setdatePickerState(newValue);
    reservationState.nbCanoe = 0;
    reservationState.nbKayak = 0;
    reservationState.nbSUP = 0;
    reservationState.nbPaxAd = 0;
    reservationState.nbPaxEnf = 0;
  };

  //Set startDate in reservationState and convert it before : 2023-8-26 ===> 203-08-26
  useEffect(() => {
    const tmpDate = new Date(
      datePickerState?.year as number,
      (datePickerState?.month as number) - 1,
      datePickerState?.day
    );
    const tmpStringDate = dateToStringSynchrone(tmpDate);
    const reservationTmp = {
      ...reservationState,
      startDate: tmpStringDate
    };
    let validationBookingTmp = { ...bookingValidation, startDate: true };
    setReservationState(reservationTmp);
    setBookingValidation(validationBookingTmp);
  }, [datePickerState]);

  useEffect(() => {}, [bookingValidation]);

  const renderCustomInputDatePicke = ({ ref }: any) => (
    <input
      ref={ref}
      className={provideValidOrNotCss(bookingValidation.startDate)}
      value={reservationState.startDate}
      id="startDate"
      name="startDate"
      readOnly></input>
  );
  // END-------------------Date Picker and validation-----------------------------------***

  // .----------------.  .----------------.  .-----------------. .----------------.  .----------------.  .----------------.
  // | .--------------. || .--------------. || .--------------. || .--------------. || .--------------. || .--------------. |
  // | |  _______     | || |  _________   | || | ____  _____  | || |  ________    | || |  _________   | || |  _______     | |
  // | | |_   __ \    | || | |_   ___  |  | || ||_   \|_   _| | || | |_   ___ `.  | || | |_   ___  |  | || | |_   __ \    | |
  // | |   | |__) |   | || |   | |_  \_|  | || |  |   \ | |   | || |   | |   `. \ | || |   | |_  \_|  | || |   | |__) |   | |
  // | |   |  __ /    | || |   |  _|  _   | || |  | |\ \| |   | || |   | |    | | | || |   |  _|  _   | || |   |  __ /    | |
  // | |  _| |  \ \_  | || |  _| |___/ |  | || | _| |_\   |_  | || |  _| |___.' / | || |  _| |___/ |  | || |  _| |  \ \_  | |
  // | | |____| |___| | || | |_________|  | || ||_____|\____| | || | |________.'  | || | |_________|  | || | |____| |___| | |
  // | |              | || |              | || |              | || |              | || |              | || |              | |
  // | '--------------' || '--------------' || '--------------' || '--------------' || '--------------' || '--------------' |
  //  '----------------'  '----------------'  '----------------'  '----------------'  '----------------'  '----------------'

  return (
    <div>
      <div className="hidden" ref={checkout}>
        <Checkout
          reservationState={reservationState}
          checkoutBoxToggle={checkoutBoxToggle}
          hideBookingBox={hideBookingBox}
          resetBookingState={resetBookingState}
          selectedPackage={selectedPackage}></Checkout>
      </div>
      <div className="flex bg-aqs-blue-300 bg-opacity-90">
        {areWeMobileState ? (
          <div></div>
        ) : (
          <div className="flex flex-col justify-center">
            <img
              id="logoTopMenu"
              src={imgBookingLeft}
              alt="Un brave chien de traineau chez aventure quatre saisons!"
            />
          </div>
        )}

        <div className="flex flex-col bg-aqs-blue-300 bg-opacity-90 text-black">
          <div
            id="interacBoxDiv"
            className="p-1 m-2 sm:mt-0 mt-14  rounded border-[1px] border-orange  bg-orange/40 text-white/80">
            <div className="relative">
              <div
                className="absolute right-0 flex flex-col text-xs justify-center text-center w-4 h-4 bg-white/40 text-black border-black/50   border-[1px] hover:bg-red/40 cursor-pointer"
                onClick={hideInteracBox}>
                <p>X</p>
              </div>
            </div>
            Il vous sera possible de payer par{" "}
            <span className="underline text-green/50">INTERAC</span> à la prochaine étape de
            confirmation.
            <br></br>
            Vous devez tout de même fournir un no. de carte valide pour des questions de sécurité
            mais celle-ci ne sera pas débitée en cas de paiement interac.
          </div>
          {/* // ***-------------------First line-----------------------------------*** */}
          <div className="flex flex-row flex-wrap justify-center sm:justify-start z-0">
            {/* // ***-------------------Activity-----------------------------------*** */}

            <div className="flex flex-col m-3">
              <select
                className={provideValidOrNotCss(bookingValidation.activite)}
                name="activite"
                id="activite"
                value={reservationState.activite}
                onChange={updateReservationStateChangeActivity}>
                <option disabled className="text-black" value="">
                  Choisissez votre forfait !
                </option>
                {packageFilteredByLanguage.map((data) => (
                  <option className="text-black" key={uuidv4()} value={data.tag}>
                    {data.name +
                      " - " +
                      data.pricing +
                      "$ / pers. - " +
                      (data.duration > 99
                        ? data.duration / 100 + " nuité(es)"
                        : data.duration < 1
                        ? data.duration * 10 + " heure(s)"
                        : data.duration + " jour(s)")}
                  </option>
                ))}
              </select>
              <ValidEntry
                valid={bookingValidation.activite}
                text={"Choisir votre activité en premier lieux!"}
              />
            </div>

            {/* // ***-------------------Date-----------------------------------*** */}
            <div className="flex flex-col m-3">
              {isLoggedContextState && currentUser.admin === 1 ? (
                <div className="">
                  <DatePicker
                    value={datePickerState}
                    onChange={handledatePickerStateChange}
                    renderInput={renderCustomInputDatePicke} // render a custom
                    calendarPopperPosition={"bottom"}
                  />
                </div>
              ) : (selectedPackage.activityType === "winter" ||
                  selectedPackage.activityType === "winterEng") &&
                !areWeWinterState ? (
                <div className="">
                  <DatePicker
                    value={datePickerState}
                    onChange={handledatePickerStateChange}
                    renderInput={renderCustomInputDatePicke} // render a custom input
                    minimumDate={winterStartDate}
                    disabledDays={disabledMondayThusday}
                    maximumDate={maxWinterDateState}
                    calendarPopperPosition={"bottom"}
                  />
                </div>
              ) : selectedPackage.activityType === "yourte" ||
                selectedPackage.activityType === "yourteEng" ? (
                <div className="z-0">
                  <DatePicker
                    value={datePickerState}
                    onChange={handledatePickerStateChange}
                    renderInput={renderCustomInputDatePicke} // render a custom input
                    minimumDate={tomorrowState}
                    disabledDays={disabledMondayThusday}
                    calendarPopperPosition={"bottom"}
                  />
                </div>
              ) : (
                <div className="">
                  <DatePicker
                    value={datePickerState}
                    onChange={handledatePickerStateChange}
                    renderInput={renderCustomInputDatePicke} // render a custom input
                    minimumDate={SummerStartDate}
                    disabledDays={disabledMondayThusday}
                    maximumDate={maxSummerDateState}
                    calendarPopperPosition={"bottom"}
                  />
                </div>
              )}

              <ValidEntry
                valid={bookingValidation.startDate}
                text={"Cliquez sur l'icone calendrier..."}
              />
            </div>

            {/* // ***-------------------Duree-----------------------------------*** */}
            <div className="flex flex-col m-3">
              <select
                name="duree"
                id="duree"
                value={reservationState.duree}
                onChange={updateReservationState}
                className={provideValidOrNotCss(bookingValidation.duree)}>
                <option className="text-black" disabled value="0">
                  Durée
                </option>
                <option className="text-black" key={uuidv4()} value={selectDurationState}>
                  {selectDurationState
                    ? selectDurationState > 99
                      ? selectDurationState / 100 + " nuité(es)"
                      : selectDurationState < 1
                      ? selectDurationState * 10 + " heure(s)"
                      : selectDurationState + " jour(s)"
                    : selectDurationState + " jour(s)"}
                </option>
              </select>
              <ValidEntry valid={bookingValidation.duree} text={"une durée..."} />
            </div>

            {/* // ***-------------------departureTime-----------------------------------*** */}
            <div className="flex flex-col m-3">
              <select
                name="departureTime"
                id="departureTime"
                value={reservationState.departureTime}
                onChange={updateReservationStateDeparture}
                className={provideValidOrNotCss(bookingValidation.departureTime)}>
                <option disabled className="text-black" value="0">
                  Heure de départ
                </option>
                {selectdepartureTimeState.map((data) => (
                  <option className="text-black" key={uuidv4()} value={data}>
                    {data + " H"}
                  </option>
                ))}
              </select>
              <ValidEntry valid={bookingValidation.departureTime} text={"et l'heure du départ"} />
            </div>
          </div>
          {/* // END-------------------First line-----------------------------------*** */}

          {/* // ***-------------------Second line-----------------------------------*** */}

          {/* // ***-------------------prename-----------------------------------*** */}
          <div className="flex flex-row flex-wrap justify-center sm:justify-start ">
            <div className="flex flex-col m-3">
              <input
                type="text"
                id="prename"
                value={reservationState.prename}
                onChange={updateReservationState}
                name="prename"
                placeholder="Votre prénom"
                pattern="^[^0-9]{3,29}$"
                className={provideValidOrNotCss(bookingValidation.prename)}
                required></input>
              <ValidEntry valid={bookingValidation.prename} text={"Entrez votre Prénom..."} />
            </div>

            {/* // ***-------------------name-----------------------------------*** */}
            <div className="flex flex-col m-3">
              <input
                type="text"
                id="name"
                value={reservationState.name}
                onChange={updateReservationState}
                name="name"
                placeholder="Votre nom"
                pattern="^[^0-9]{3,29}$"
                required
                className={provideValidOrNotCss(bookingValidation.name)}
                size={10}></input>
              <ValidEntry valid={bookingValidation.name} text={"votre nom..."} />
            </div>

            {/* // ***-------------------email-----------------------------------*** */}
            <div className="flex flex-col m-3 ">
              <input
                type="text"
                id="courriel"
                name="courriel"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                required
                value={reservationState.courriel}
                onChange={updateReservationState}
                placeholder="Votre courriel"
                className={provideValidOrNotCss(bookingValidation.courriel)}></input>
              <ValidEntry valid={bookingValidation.courriel} text={"un courriel et un ..."} />
            </div>

            {/* // ***-------------------tel-----------------------------------*** */}
            <div className="flex flex-col m-3 ">
              <input
                type="text"
                id="tel"
                name="tel"
                value={reservationState.tel}
                onChange={updateReservationState}
                pattern="^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$"
                required
                placeholder="Votre téléphone"
                className={provideValidOrNotCss(bookingValidation.tel)}
                size={8}></input>
              <ValidEntry valid={bookingValidation.tel} text={"téléphone : 111-222-3333"} />
            </div>
          </div>
          {/* // END-------------------Second line-----------------------------------*** */}

          {/* // ***-------------------Third line-----------------------------------*** */}

          {/* // ***-------------------nbAdultes-----------------------------------*** */}
          <div className="flex flex-row flex-wrap justify-center sm:justify-start">
            <div className="flex flex-col m-3">
              <select
                name="nbPaxAd"
                id="nbPaxAd"
                value={reservationState.nbPaxAd}
                onChange={updateReservationState}
                className={provideValidOrNotCss(bookingValidation.nbPaxAd)}>
                <option disabled className="text-black" value="0">
                  {selectNbAdultsState.length < 1 ? "Complet" : "Adultes(s)"}
                </option>
                {selectNbAdultsState.map((data, index) => (
                  //index + 1 : to never have 0 adultes see adjustment in ParseInventToSelectItem().
                  <option className="text-black" key={uuidv4()} value={index + 1}>
                    {data}
                  </option>
                ))}
              </select>
              <ValidEntry valid={bookingValidation.nbPaxAd} text={"Nombre d'adultres..."} />
            </div>

            {/* // ***-------------------nbEnfants-----------------------------------****/}
            <div className="flex flex-col m-3">
              <select
                name="nbPaxEnf"
                id="nbPaxEnf"
                value={reservationState.nbPaxEnf}
                onChange={updateReservationState}
                className={provideValidOrNotCss(bookingValidation.nbPaxEnf)}>
                <option disabled className="text-black" value="0">
                  {selectNbEnfantsState.length < 1 ? "Complet" : "Enfants (12 ANS ET -)"}
                </option>
                {selectNbEnfantsState.map((data, index) => (
                  <option className="text-black" key={uuidv4()} value={index}>
                    {data}
                  </option>
                ))}
              </select>
              <ValidEntry valid={bookingValidation.nbPaxEnf} text={"et d'enfants (12 ans et -)"} />
            </div>

            {/* // ***-------------------yourte-----------------------------------*** */}
            {selectedPackage.activityType === "yourte" ||
            selectedPackage.activityType === "yourteEng" ? (
              <div className="flex flex-col m-3">
                {summerValidityBecauseNotVisible(true)}
                <select
                  name="yourte"
                  id="yourte"
                  value={reservationState.yourte}
                  onChange={updateReservationState}
                  className={provideValidOrNotCss(bookingValidation.yourte)}>
                  <option disabled className="text-black" value="aucune">
                    {selectYourteState.length < 1 ? "Complet" : "Aucune Yourte"}
                  </option>
                  {selectYourteState.map((data) => (
                    <option className="text-black" key={uuidv4()} value={data}>
                      {"Yourte " + data}
                    </option>
                  ))}
                </select>
                <ValidEntry valid={bookingValidation.yourte} text={"Sélectionnez une yourte"} />
              </div>
            ) : (
              <div>
                {selectedPackage.activityType === "summer" ? (
                  <div>
                    <div className="flex flex-wrap justify-center">
                      {yourteValidityBecauseNotVisible(true)}
                      {/* // ***-------------------canoe-----------------------------------*** */}

                      <div className="flex flex-col m-3">
                        <select
                          name="nbCanoe"
                          id="nbCanoe"
                          value={reservationState.nbCanoe}
                          onChange={updateReservationState}
                          className={
                            selectCanotState.length <= 1
                              ? provideValidOrNotCss(true)
                              : provideValidOrNotCss(bookingValidation.nbCanoe)
                          }>
                          <option disabled className="text-black" value="0">
                            {selectCanotState.length <= 1 ? "Complet" : "Canot(s)"}
                            {selectCanotState.length <= 1 ? (bookingValidation.nbCanoe = true) : ""}
                          </option>
                          {selectCanotState.map((data, index) => (
                            <option className="text-black" key={uuidv4()} value={index}>
                              {data}
                            </option>
                          ))}
                        </select>
                        <ValidEntry
                          valid={bookingValidation.nbCanoe}
                          text={"Sélectionnez les canots..."}
                        />
                      </div>

                      {/* // ***-------------------kayak-----------------------------------*** */}

                      <div className="flex flex-col m-3">
                        <select
                          name="nbKayak"
                          id="nbKayak"
                          value={reservationState.nbKayak}
                          onChange={updateReservationState}
                          className={
                            selectKayakState.length <= 1
                              ? provideValidOrNotCss(true)
                              : provideValidOrNotCss(bookingValidation.nbKayak)
                          }>
                          <option disabled className="text-black" value="0">
                            {selectKayakState.length <= 1 ? "Complet" : "Kayaks(s)"}
                            {selectCanotState.length <= 1 ? (bookingValidation.nbKayak = true) : ""}
                          </option>
                          {selectKayakState.map((data, index) => (
                            <option className="text-black" key={uuidv4()} value={index}>
                              {data}
                            </option>
                          ))}
                        </select>
                        <ValidEntry valid={bookingValidation.nbKayak} text={"les kayaks..."} />
                      </div>

                      {/* // ***-------------------sup-----------------------------------*** */}

                      <div className="flex flex-col m-3">
                        <select
                          name="nbSUP"
                          id="nbSUP"
                          value={reservationState.nbSUP}
                          onChange={updateReservationState}
                          className={
                            selectSupState.length <= 1
                              ? provideValidOrNotCss(true)
                              : provideValidOrNotCss(bookingValidation.nbSUP)
                          }>
                          <option disabled className="text-black" value="0">
                            {selectSupState.length <= 1 ? "Complet" : "SUPs(s)"}
                            {selectCanotState.length <= 1 ? (bookingValidation.nbSUP = true) : ""}
                          </option>
                          {selectSupState.map((data, index) => (
                            <option className="text-black" key={uuidv4()} value={index}>
                              {data}
                            </option>
                          ))}
                        </select>
                        <ValidEntry
                          valid={bookingValidation.nbSUP}
                          text={"et les Stand-up PaddleBoard"}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    {(yourteValidityBecauseNotVisible(true), summerValidityBecauseNotVisible(true))}
                  </div>
                )}
              </div>
            )}
          </div>

          {/* // END-------------------Third line-----------------------------------*** */}

          {/* // ***-------------------Forth line-----------------------------------*** */}

          {/* // ***-------------------pricing-----------------------------------*** */}
          <div className="flex w-auto justify-around  ">
            {isLoggedContextState && currentUser.admin === 1 ? (
              <div className="flex  justify-center m-3 text-black text-4xl">
                <input
                  type="number"
                  step={0.01}
                  name="pricing"
                  id="pricing"
                  onChange={updateReservationStateWithFloat}
                  value={(reservationState.pricing * 1).toFixed(2)}
                  className="flex p-3  w-52 text-center rounded bg-aqs-blue-50/20   "
                  required></input>
                <div className="flex items-center text-5xl">{" $"}</div>
              </div>
            ) : (
              <div className="flex  justify-center m-3 text-black text-4xl">
                <input
                  type="number"
                  step={0.01}
                  name="pricing"
                  id="pricing"
                  onChange={updateReservationState}
                  value={(reservationState.pricing * 1).toFixed(2)}
                  className="flex p-3  w-52 text-center rounded bg-aqs-blue-50/20   "
                  disabled
                  required></input>
                <div className="flex items-center text-5xl">{" $"}</div>
              </div>
            )}
          </div>
          {/* // ***-------------------message confirmation-----------------------------------*** */}
          <div className="flex text-aqs-green-200 text-center justify-center">
            {messageConfirmation}
          </div>
          {/* // END-------------------Forth line-----------------------------------*** */}

          {/* // ***-------------------Fifth line-----------------------------------*** */}

          {/* // ***-------------------modePaiement-----------------------------------*** */}
          <div className="flex flex-row flex-wrap justify-center sm:justify-start">
            <div className="flex flex-col m-3">
              <select
                name="modePaiement"
                id="modePaiement"
                value={reservationState.modePaiement}
                onChange={updateReservationState}
                className={provideValidOrNotCss(bookingValidation.modePaiement)}
                required>
                <option disabled value="">
                  Mode de Paiement
                </option>
                <option className="text-black" value="visa">
                  Visa
                </option>
                <option className="text-black" value="masterCard">
                  MasterCard
                </option>
              </select>
              <ValidEntry
                valid={bookingValidation.modePaiement}
                text={"Sélectionnez un mode de paiement"}
              />
            </div>
            {/* // ***-------------------specialCode-----------------------------------*** */}
            <div className="flex flex-col m-3">
              <input
                type="text"
                id="specialCode"
                name="specialCode"
                value={reservationState.specialCode}
                onChange={updateReservationState}
                placeholder="NOTES SUPP."
                className={provideValidOrNotCss(bookingValidation.specialCode)}
                size={5}
                required></input>
              <ValidEntry valid={bookingValidation.specialCode} />
            </div>
            {/* // ***-------------------enterprise-----------------------------------*** */}
            <div className="flex flex-col m-3">
              <input
                type="cardNumber"
                id="enterprise"
                name="enterprise"
                value={reservationState.enterprise}
                onChange={updateReservationState}
                placeholder="NO. DE CARTE DE CRÉDIT"
                className={provideValidOrNotCss(bookingValidation.enterprise)}
                maxLength={16}
                pattern="^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14})$"
                required></input>
              <ValidEntry
                valid={bookingValidation.enterprise}
                text={"Entrez une Carte de crédit valide"}
              />
            </div>
            {/* // ***-------------------enterprise01-----------------------------------*** */}
            <div className="flex flex-col m-3">
              <input
                type="text"
                id="enterprise01"
                size={4}
                name="enterprise01"
                value={reservationState.enterprise01}
                onChange={updateReservationState}
                placeholder="CVV"
                pattern="^[0-9]{3,4}$"
                className={provideValidOrNotCss(bookingValidation.enterprise01)}
                required></input>
              <ValidEntry valid={bookingValidation.enterprise01} text={"Entrez le CVV..."} />
            </div>
            {/* // ***-------------------enterprise01-----------------------------------*** */}
            <div className="flex flex-col m-3">
              <select
                name="expiryMonth"
                id="expiryMonth"
                className={provideValidOrNotCss(bookingValidation.expiryMonth)}
                value={reservationState.expiryMonth}
                onChange={updateReservationState}
                required>
                <option className="text-black" value="0">
                  MM
                </option>
                <option className="text-black" value="1">
                  01
                </option>
                <option className="text-black" value="2">
                  02
                </option>
                <option className="text-black" value="3">
                  03
                </option>
                <option className="text-black" value="4">
                  04
                </option>
                <option className="text-black" value="5">
                  05
                </option>
                <option className="text-black" value="6">
                  06
                </option>
                <option className="text-black" value="7">
                  07
                </option>
                <option className="text-black" value="8">
                  08
                </option>
                <option className="text-black" value="9">
                  09
                </option>
                <option className="text-black" value="10">
                  10
                </option>
                <option className="text-black" value="11">
                  11
                </option>
                <option className="text-black" value="12">
                  12
                </option>
              </select>
              <ValidEntry valid={bookingValidation.expiryMonth} text={"le mois et..."} />
            </div>
            {/* // ***-------------------expiryYear-----------------------------------*** */}
            <div className="flex flex-col m-3">
              <select
                name="expiryYear"
                id="expiryYear"
                className={provideValidOrNotCss(bookingValidation.expiryYear)}
                value={reservationState.expiryYear}
                onChange={updateReservationState}
                required>
                <option value="0">AA</option>
                <option className="text-black" value="2023">
                  23
                </option>
                <option className="text-black" value="2024">
                  24
                </option>
                <option className="text-black" value="2025">
                  25
                </option>
                <option className="text-black" value="2026">
                  26
                </option>
                <option className="text-black" value="2027">
                  27
                </option>
                <option className="text-black" value="2028">
                  28
                </option>
                <option className="text-black" value="2029">
                  29
                </option>
                <option className="text-black" value="2030">
                  30
                </option>
              </select>
              <ValidEntry valid={bookingValidation.expiryYear} text={"l'année d'expiration"} />
            </div>
          </div>

          <div
            className="absolute bg-white/40 sm:right-[2%] right-[0%] text-black border-black/50 m-2 p-2 border-[1px] hover:bg-aqs-blue-200 cursor-pointer"
            onClick={hideBookingBox}>
            <p>X</p>
          </div>

          {/* // END-------------------Fifth line-----------------------------------*** */}

          {/* // ***-------------------Sixth line-----------------------------------*** */}
          <div>
            {checkAllForm() ? (
              <div
                className="flex flex-row m-3 flex-wrap justify-around bg-aqs-blue-100 hover:bg-green/30 cursor-pointer border-2 border-green rounded-lg"
                onClick={checkoutBoxToggle}>
                <div className="flex m-3 text-black text-2xl font-bold ">
                  <p>Réserver</p>
                </div>
              </div>
            ) : (
              <div
                className="flex flex-row m-3 flex-wrap justify-around bg-aqs-blue-100 hover:bg-red/30 cursor-pointer  border-2 border-red rounded-lg"
                onClick={checkoutBoxToggle}>
                <div className="flex m-3 text-black text-2xl font-bold ">
                  <p>Réserver</p>
                </div>
              </div>
            )}
          </div>

          {/* // END-------------------Sixth line-----------------------------------*** */}
        </div>
        {areWeMobileState ? (
          <div></div>
        ) : (
          <div className="flex flex-col justify-center">
            <img
              className=""
              id="logoTopMenu"
              src={imgBookingRight}
              alt="Un brave chien de traineau chez aventure quatre saisons!"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Reserver;
